import DecorBlob from "@/Components/Decor/DecorBlob.jsx";
import DecorContainer from "@/Components/Decor/DecorContainer.jsx";
import DecorOverflow from "@/Components/Decor/DecorOverflow.jsx";
import { useLaravelReactI18n } from "laravel-react-i18n";
import ContactsForm from "./ContactsForm";

function LocationCard() {
    const { t } = useLaravelReactI18n();

    const locationCardData = [
        {
            title: t("site.locationCardData.cardTitle1"),
            icon: require("@images/pages/home/contact__location-icon-1.svg")
                .default,
            address: t("site.locationCardData.cardAddress1"),
        },
        {
            title: t("site.locationCardData.cardTitle2"),
            icon: require("@images/pages/home/contact__location-icon-2.svg")
                .default,
            address: t("site.locationCardData.cardAddress2"),
        },
        {
            title: t("site.locationCardData.cardTitle3"),
            icon: require("@images/pages/home/contact__location-icon-3.svg")
                .default,
            address: t("site.locationCardData.cardAddress3"),
        },
    ];

    return (
        <div className={"location-cards"}>
            {locationCardData.map((data, index) => (
                <div className={"location-card"} key={index}>
                    <div className={"location-card__head"}>
                        <p className={"location-card__head-title"}>
                            {data.title}
                        </p>
                        <div className={"location-card__head-preview"}>
                            <img src={data.icon} alt="address-icon" />
                        </div>
                    </div>
                    <div
                        className={"location-card__address"}
                        dangerouslySetInnerHTML={{ __html: data.address }}
                    ></div>
                </div>
            ))}
        </div>
    );
}

const Footer = ({ pageProps }) => {
    const { t } = useLaravelReactI18n();

    return (
        <>
            <section
                className={"contact container mb-80px ph:mb-[160px]"}
                id="contacts"
            >
                <div className={"container__inner"}>
                    <div className={"contact__row"}>
                        <div className={"contact__text"}>
                            <h2
                                className={"mb-48px ph:mb-20px"}
                                id={"contact"}
                                dangerouslySetInnerHTML={{
                                    __html: t("site.contactBlock.subTitle"),
                                }}
                            ></h2>
                            <p
                                className={
                                    "contact__subtitle text-[24rem] max-w-[380px] font-medium"
                                }
                                dangerouslySetInnerHTML={{
                                    __html: pageProps.settings.find(
                                        (el) => el.key === "contacts"
                                    )?.value,
                                }}
                            ></p>
                        </div>
                        <ContactsForm locale={pageProps.locale} />
                    </div>
                </div>
            </section>

            <section className={"get-involved container pb-80px ph:pb-100px"}>
                <div className={"container__inner"}>
                    <div className={"get-involved__row mb-120px ph:mb-30px"}>
                        <div className={"get-involved__row-content ph:order-3"}>
                            <p
                                className={
                                    "text-[40rem] mb-[190px] font-extrabold ph:hidden"
                                }
                            >
                                {t("site.footerBlock.subTitle")}
                            </p>
                            <div className={"flex flex-col gap-[28px]"}>
                                <div
                                    className={
                                        "flex gap-[14px] ph:justify-center"
                                    }
                                >
                                    <a
                                        href="https://www.facebook.com/share/cQNAehFC7Qeg2SVz/?mibextid=LQQJ4d"
                                        target="_blank"
                                    >
                                        <img
                                            src={require("@images/pages/home/contact__icon-fb.png")}
                                            alt="fb"
                                            width="39.5"
                                        />
                                    </a>
                                    <a
                                        href="https://www.linkedin.com/company/vivantieurope"
                                        target="_blank"
                                    >
                                        <img
                                            src={require("@images/pages/home/contact__icon-in.png")}
                                            alt="in"
                                            width="39.5"
                                        />
                                    </a>
                                </div>
                                <p>
                                    Vivanti<sup>©</sup> 2014-2024
                                </p>
                            </div>
                        </div>
                        <div className={"get-involved__reward ph:order-1"}>
                            <p
                                className={
                                    "get-involved__reward-text hidden ph:block text-[40rem] font-extrabold"
                                }
                            >
                                {t("site.footerBlock.subTitle")}
                            </p>
                            <img
                                src={require("@images/pages/home/contact__revard.png")}
                                alt="reward"
                            />
                        </div>
                        <div className={"get-involved__row-content ph:order-2"}>
                            <p
                                className={
                                    "font-medium text-[36rem] max-w-[440px]"
                                }
                                dangerouslySetInnerHTML={{
                                    __html: t("site.footerBlock.text"),
                                }}
                            ></p>
                            <div
                                className={
                                    "flex justify-end gap-[37px] mt-125px text-[24rem] ph:mt-30px ph:text-center ph:justify-center"
                                }
                            >
                                <a
                                    href="/page/privacy-statement"
                                    target="_blank"
                                    className={"underline hover:no-underline"}
                                >
                                    {t("site.footerBlock.footerPrivacy")}
                                </a>
                                <a
                                    href="/page/terms-and-conditions"
                                    target="_blank"
                                    className={"underline hover:no-underline"}
                                >
                                    {t("site.footerBlock.footerTermOfUse")}
                                </a>
                            </div>
                        </div>
                    </div>
                    <LocationCard></LocationCard>
                </div>
                <DecorOverflow>
                    <DecorContainer>
                        <DecorBlob
                            typeNum={4}
                            width={1168.19}
                            blur={150}
                            rotate={-49.21}
                            opacity={1}
                            x={-74}
                            yBottom={13}
                        />
                        <DecorBlob
                            typeNum={4}
                            width={1168.19}
                            blur={150}
                            rotate={81.61}
                            opacity={1}
                            x={136}
                            yBottom={53}
                        />
                    </DecorContainer>
                </DecorOverflow>
            </section>
        </>
    );
};

export default Footer;
