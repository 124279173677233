import React, {useEffect} from 'react';
import '@scss/popup.scss';
import {setLockScroll} from "@/Utils/index.js";
import {createPortal} from "react-dom";

function PopupPortal ({children}) {
    return (
        <>
            {createPortal(children, document.getElementById('app'), 'popup')}
        </>
    )
}

function PopupOverlay ({children}) {
    return (
        <div className={'popup-overlay'}>
            {children}
        </div>
    )
}

export function PopupClose ({onClick}) {
    return (
        <button className={'popup-content__btn-close'} onClick={onClick}></button>
    )
}

function PopupContent ({onRequestClose, children}) {

    useEffect(() => {

        function handlerOutsidePopup (e) {
            if ( e.target.classList.contains('popup-overlay') ) {
                onRequestClose();
            }
        }

        const onOpen = () => {
            setLockScroll(true);
            document.body.addEventListener('click', handlerOutsidePopup);
        }

        const onClose = () => {
            setLockScroll(false);
            document.body.removeEventListener('click', handlerOutsidePopup);
        }

        onOpen();
        return () => {
            onClose();
        };
    }, []);

    return (
        <div className={'popup-content'}>
            {children}
        </div>
    )
}

const Popup = (props) => {
    return (
        <>
            {
                props.isOpen &&
                <PopupPortal>
                    <PopupOverlay>
                        <PopupContent {...props}/>
                    </PopupOverlay>
                </PopupPortal>
            }
        </>

    );
};

export default Popup;
