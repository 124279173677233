import React from 'react';

export default function DecorOverflow ({children}) {

    return (
        <>
            <div className={`decor-container-overflow`}>
                {children}
            </div>
        </>
    );
}
