import React from 'react';

const FormGroupInput = ({labelText, className = '', ...props}) => {
    return (
        <div className={'form__group'}>
            <label className={'form__group-label'} dangerouslySetInnerHTML={{__html: labelText}}></label>
            <input
                {...props}
                className={`form__group-control ` + className}
            />
        </div>
    );
};

export default FormGroupInput;
