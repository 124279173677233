import React, {useEffect, useState} from 'react';
import {useForm} from "@inertiajs/react";
import {useLaravelReactI18n} from "laravel-react-i18n";

const ContactsForm = ({locale}) => {

    const [isSending, setIsSending] = useState(false);
    const [showSecondForm, setShowSecondForm] = useState(false);
    const {t} = useLaravelReactI18n();

    const { data, setData, post, processing, reset } = useForm({
        name: '',
        phone: '',
        email: '',
        message: '',
        preferable_time: '',
        agree: false,
        type: 'callback',
    });

    useEffect(() => {
        return () => {
            reset('password');
        };
    }, []);

    const switchForms = (e) => {
        e.preventDefault();
        setShowSecondForm(!showSecondForm);
    }

    const submit = (e) => {
        e.preventDefault();

        if (e.target.classList.contains('form--book-a-call')) {
            setData('type', 'callback');
        } else {
            setData('type', 'contacts');
        }

        post('/contact', {
            preserveScroll: true,
            onSuccess: () => {
                reset('name', 'email', 'phone', 'message', 'agree', 'preferable_time');
                setIsSending(true);
            },
        });
    };

    return (
        <>
            {!isSending
                ?
                (<>
                    {!showSecondForm
                        ?
                        <form className={'form'} onSubmit={submit}>

                            <div className={`form__row ${locale === 'fr' ? '!flex-col !w-[100%]' : ''}`}>
                                <div className={'form__group'}>
                                    <label className={'form__group-label'}>{t('site.contactBlock.labelName')}</label>
                                    <input
                                        className={'form__group-control'}
                                        id="name"
                                        name="name"
                                        type="text"
                                        required={true}
                                        value={data.name}
                                        onChange={(e) => setData('name', e.target.value)}
                                    />
                                </div>
                                <div className={'form__group'}>
                                    <label className={'form__group-label'}>{t('site.contactBlock.labelPhone')}</label>
                                    <input
                                        className={'form__group-control'}
                                        id="phone"
                                        name="phone"
                                        type="text"
                                        required={true}
                                        value={data.phone}
                                        onChange={(e) => setData('phone', e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className={'form__group'}>
                                <label className={'form__group-label'} dangerouslySetInnerHTML={{__html: t('site.contactBlock.labelEmail')}}></label>
                                <input
                                    className={'form__group-control'}
                                    id="email"
                                    name="email"
                                    type="email"
                                    required={true}
                                    value={data.email}
                                    onChange={(e) => setData('email', e.target.value)}
                                />
                            </div>

                            <div className={'form__group form__group--textarea'}>
                                <label className={'form__group-label'} dangerouslySetInnerHTML={{__html: t('site.contactBlock.labelMessage')}}></label>
                                <textarea
                                    className={'form__group-control'}
                                    id="message"
                                    name="message"
                                    required={false}
                                    value={data.message}
                                    onChange={(e) => setData('message', e.target.value)}
                                    cols="30"
                                    rows="10"
                                />
                            </div>

                            <div className={'form__group form__group--checkbox my-16px'}>
                                <label className={'form__group-label'}>
                                    <input
                                        className={'form__group-control'}
                                        id="agree"
                                        name="agree"
                                        type="checkbox"
                                        required={true}
                                        value={data.agree}
                                        checked={data.agree}
                                        onChange={(e) => setData('agree', e.target.checked)}
                                    />
                                    <span className={'form__group-control--custom-checkbox'}></span>
                                    <span>
                                        {t('site.contactBlock.agreeText')} {t('site.contactBlock.privacyStatement')}
                                        {/*<a href="/" className={'underline hover:no-underline'}>{t('site.contactBlock.privacyStatement')}</a>*/}
                                    </span>
                                </label>
                            </div>

                            <div className={'form__row form__row--controls'}>
                                <button className={`form__btn form__btn--submit ${locale === 'fr' ? '!p-[25px] !text-[16rem]' : ''}`} disabled={processing}>{t('site.contactBlock.textButton1')}</button>
                                <span className={'text-[24rem]'}>{t('site.contactBlock.or')}</span>
                                <button className={`form__btn form__btn--switch ${locale === 'fr' ? '!p-[25px] !text-[16rem]' : ''}`} disabled={processing} onClick={switchForms}>{t('site.contactBlock.textButton2')}</button>
                            </div>

                        </form>
                        :
                        <form className={'form form--book-a-call'} onSubmit={submit}>
                            <div className={`form__row ${locale === 'fr' ? '!flex-col !w-[100%]' : ''}`}>
                                <div className={'form__group'}>
                                    <label className={'form__group-label'}>{t('site.contactBlock.labelName')}</label>
                                    <input
                                        className={'form__group-control'}
                                        id="name"
                                        name="name"
                                        type="text"
                                        required={true}
                                        value={data.name}
                                        onChange={(e) => setData('name', e.target.value)}
                                    />
                                </div>
                                <div className={'form__group'}>
                                    <label className={'form__group-label'}>{t('site.contactBlock.labelPhone')}</label>
                                    <input
                                        className={'form__group-control'}
                                        id="phone"
                                        name="phone"
                                        type="text"
                                        required={true}
                                        value={data.phone}
                                        onChange={(e) => setData('phone', e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className={'form__group'}>
                                <label className={'form__group-label'} dangerouslySetInnerHTML={{__html: t('site.contactBlock.labelPreferableTime')}}></label>
                                <input
                                    className={'form__group-control'}
                                    id="preferable_time"
                                    name="preferable_time"
                                    type="text"
                                    required={false}
                                    value={data.preferable_time}
                                    onChange={(e) => setData('preferable_time', e.target.value)}
                                />
                            </div>

                            <div className={'form__group'}>
                                <label className={'form__group-label'} dangerouslySetInnerHTML={{__html: t('site.contactBlock.labelEmail2')}}></label>
                                <input
                                    className={'form__group-control'}
                                    id="email"
                                    name="email"
                                    type="email"
                                    required={false}
                                    value={data.email}
                                    onChange={(e) => setData('email', e.target.value)}
                                />
                            </div>

                            <div className={'form__group form__group--checkbox my-16px'}>
                                <label className={'form__group-label'}>
                                    <input
                                        className={'form__group-control'}
                                        id="agree"
                                        name="agree"
                                        type="checkbox"
                                        required={true}
                                        value={data.agree}
                                        checked={data.agree}
                                        onChange={(e) => setData('agree', e.target.checked)}
                                    />
                                    <span className={'form__group-control--custom-checkbox'}></span>
                                    <span>{t('site.contactBlock.agreeText')} <a href="/" className={'underline hover:no-underline'}>{t('site.contactBlock.privacyStatement')}</a></span>
                                </label>
                            </div>

                            <div className={'form__row form__row--controls'}>
                                <button className={`form__btn form__btn--switch ${locale === 'fr' ? '!p-[25px] !text-[16rem]' : ''}`} disabled={processing} onClick={switchForms}>{t('site.contactBlock.textButton3')}</button>
                                <span className={'text-[24rem]'}>{t('site.contactBlock.or')}</span>
                                <button className={`form__btn form__btn--submit ${locale === 'fr' ? '!p-[25px] !text-[16rem]' : ''}`} disabled={processing}>{t('site.contactBlock.textButton1')}</button>
                            </div>

                        </form>
                    }
                </>)
                :
                <div className='flex justify-center items-center text-center max-w-[520px] w-[100%] h-[530px] ph:h-[250px]'>
                    <p className="font-bold text-[50rem] text-[#FFBEE8] text-center">{t('site.contactBlock.formSendingResult')}</p>
                </div>

            }

        </>
    );
};

export default ContactsForm;
