import React from 'react';

const Button = ({className='', disabled, children, ...props}) => {
    return (
        <button
            {...props}
            className={`btn-primary `+className}
            dangerouslySetInnerHTML={{__html: children}}
        ></button>
    );
};

export default Button;
