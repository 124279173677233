import React, {useState} from 'react';
import Popup, {PopupClose} from "@/Components/Popup.jsx";
import FormGroupInput from "@/Components/Form/FormGroupInput.jsx";
import Form from "@/Components/Form/Form.jsx";
import FormGroupTextarea from "@/Components/Form/FormGroupTextarea.jsx";
import Button from "@/Components/UI/Button.jsx";
import {useForm} from "@inertiajs/react";

const ChatBotReport = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const {setData, processing, post, reset} = useForm({
        name: '',
        email: '',
        message: '',
        type: 'report',
    });

    const submit = (e) => {
        e.preventDefault();

        post('/contact', {
            preserveScroll: true,
            onSuccess: () => {
                reset('name', 'email', 'message');
                setIsSending(true);
            },
        });

    };

    const closePopup = () => {
        setIsOpen(false);
        setIsSending(false);
    };

    return (
        <>
            <button className={'chat-bot__report-btn'} onClick={()=>setIsOpen(true)}>
                <span className={'chat-bot__report-btn-icon'}></span>
                <span>Report a problem</span>
            </button>
            <Popup
                isOpen={isOpen}
                onRequestClose={closePopup}
            >
                <PopupClose onClick={closePopup} />
                {!isSending ?
                    <>
                        <h3 className="text-center text-[#FBAEE0] font-medium text-[48rem] mb-32px ph:text-[32rem]">Report a problem</h3>
                        <Form className={'mx-auto'} onSubmit={submit}>
                            <FormGroupInput
                                labelText={'Your name'}
                                name={'name'}
                                type={'text'}
                                id={'name'}
                                required={'true'}
                                onChange={(e) => setData('name', e.target.value)}
                            />
                            <FormGroupInput
                                labelText={'Your Email'}
                                name={'email'}
                                type={'email'}
                                id={'email'}
                                required={'true'}
                                onChange={(e) => setData('email', e.target.value)}
                            />
                            <FormGroupTextarea
                                labelText={'Describe the problem:'}
                                name={'message'}
                                id={'message'}
                                required={'true'}
                                onChange={(e) => setData('message', e.target.value)}
                            />
                            <Button disabled={processing}>Submit</Button>
                        </Form>
                    </>

                :
                    <div className='mx-auto flex flex-col gap-[16rem] justify-center items-center text-center max-w-[520px] w-[100%] h-[530px] ph:h-[250px]'>
                        <p className="font-bold text-[50rem] ph:text-[32rem] text-[#FFBEE8] text-center">Thank you for your report!</p>
                        <Button onClick={closePopup}>Close</Button>
                    </div>
                }
            </Popup>
        </>
    );
};

export default ChatBotReport;
