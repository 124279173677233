import React from 'react';

const Form = ({children, className, ...props}) => {
    return (
        <form  className={`form `+className} {...props} >
            {children}
        </form>
    );
};

export default Form;
