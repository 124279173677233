import React from 'react';

export default function DecorContainer ({children}) {



    return (
        <div className={`decor-container`}>
            {children}
        </div>
    );
}
